import { useEffect } from 'react';
import { navigate } from 'gatsby';
import * as React from 'react';

import Seo from '../../components/seo';


import Layout from '../../components/Layout/Layout';

const Services = ()=>{
    useEffect(()=>{
        setTimeout(()=>{
            navigate(
                "/services/bathroom",
                {replace: true}
                );
        }, 0)
    },[])
    return (
        <Layout>
             <Seo 
                title='Home Renovation Services'
                description='Michael Kolonay Renovations offers a variety of home renovation services for your bathroom, kitchen, and basement.'
                />
            <main>
                
            </main>
        </Layout>
    );
}

export default Services;